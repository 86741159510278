<template>
    <contacts-module-component/>
</template>

<script>
    import ContactsModuleComponent from "@/components/admin/ContactsModuleComponent";
    
    export default {
        name: "ContactsModules",
        title: "Gestión de Contactos | Turismo BC",
        components: {ContactsModuleComponent}
    }
</script>

<style scoped>

</style>