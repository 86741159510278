import axios from "axios";
import store from "@/store/store.js";

export default new class {

    #baseUrl = null;
    errormsg = '';
    message = null;
    header = {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            Authorization: 'Bearer ' + store.getters.getToken
        }
    };

    constructor() {
        // Inicializar campos y propiedades.
        this.#baseUrl = store.getters.getBaseURL;
    }

    async getAllRecords() {
        try {
            let response = await axios.get(this.#baseUrl + "/contacts");
            // console.log('response: ', response);
            if (response.data.success) {
                // console.log("records:", response.data);
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            // console.error('Error fatal:', error);
            return this.handleError(error);
        }
    }

    async getRecord(id) {
        try {
            let response = await axios.get(`${this.#baseUrl}/contacts/${id}`);
            if (response.data.success) {
                // console.log("records:", response.data);
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            // console.log(error);
            return this.handleError(error);
        }
    }

    async addRecord(data) {
        // console.log('Adding Record.', data);
        try {
            let formData = new FormData();
            formData.append("name", data.name);
            formData.append("phone", data.phone);
            formData.append("email", data.email);
            formData.append("service", data.service);
            // formData.append("comment", data.comment);
            let response = await axios.post(this.#baseUrl + "/contacts", formData);
            if (response.data.success) {
                // console.log('Respuesta: ', response.data);
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            let result;
            result = this.handleError(error);
            return result;
        }
    }

    async attendContact(data) {
        try {
            let formData = new FormData();
            formData.append("id", data.id);
            formData.append("attended", data.attended);
            let response = await axios.post(this.#baseUrl + '/register-attended/contacts', formData);
            // console.log('Respuesta: ', response);
            if (response.data.success) {
                // console.log('Respuesta: ', response.data);
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            let result;
            result = this.handleError(error);
            return result;
        }
    }

    // Pushes posts to the server when called.
    updateRecord(id, formData) {
        let result = null;
        // console.log('Updating Record: %s %s ', id, formData);
        axios.put(`${this.#baseUrl}/contacts/${id}`, formData)
            .then(response => {
                // console.log('Respuesta: ', response.data);
                result = response.data;
            })
            /* handle error */
            .catch(error => {
                    result = this.handleError(error);
                }
            );
        return result;
    }

    deleteRecord(id) {
        let result = null;
        // console.log('Deleting Record: %s ', id);
        axios.delete(`${this.#baseUrl}/contacts/${id}`)
            .then(response => {
                // console.log('Respuesta: ', response.data);
                result = response.data;
            })
            /* handle error */
            .catch(error => {
                    // console.log('Error: ', response);
                    result = this.handleError(error);
                }
            );
        return result;
    }

    handleError(error) {
        let valueError;
        if (error.response) {
            // console.log('Error response: %s', error.response.data.message);
            valueError = error.response.data.messaje;
        } else if (error.request) {
            valueError = error.request.data;
            // console.log(error.request);
            // console.error('Error request: ', error.request.data);
        } else {
            valueError = error.message;
            // console.log('Error', error.message);
        }
        return valueError;
    }
}